<template>
  <footer>
    <div class="footer-container">
      <span>&#169; 2024 Dinobergue Viana</span>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>

<style scoped>
footer {
  background: var(--white-100);
}
.footer-container {
  padding: 1em;
  text-align: center;
  color: var(--gray-200);
}
</style>